<template>
  <app-list-view
    server-side
    show-group-by
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.MemorandumOfAssociation')"
    :createTo="{ name: 'MemorandumOfAssociationCreate' }"
    :editTo="{ name: 'MemorandumOfAssociationEdit' }"
    :headers="headers"
    :query="{ document_template_id__name: 'บริคณห์สนธิ' }"
  >
    <template v-slot:item.type="{ item }">
      {{ $t(`label.${item.type}`) }}
    </template>

    <template v-slot:item.document_template_id="{ item }">
      {{ item.document_template_id.name }}
    </template>
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'MemorandumofAssociation',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.documentName'),
          value: 'document_name'
        },
        {
          text: this.$t('fields.companyName'),
          value: 'fields.name',
          mongo: true
        },
        { text: this.$t('fields.taxID'), value: 'fields.taxid', mongo: true },
        {
          text: this.$t('fields.issueDate'),
          value: 'fields.issuedate',
          mongo: true
        },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: 'customer company',
          value: 'customer_company_id.name'
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'MemorandumOfAssociation'
    })
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    }
  }
}
</script>
